<template>
  <div class="property-detail-box">
    <div class="form-box">
      <p class="title">Property Detail</p>
      <p class="address" v-if="$route.params.id">
        {{ data.StreetNumber }} {{ data.StreetName }}
      </p>
      <el-form :model="data" ref="linkform" :rules="rules">
        <div class="row property-detail-row">
          <div class="upload-img">
            <el-form-item prop="images">
              <div class="row">
                <img class="img" v-if="data.images.length != 0" :src="data.images[0].small_url"
                  alt=""
                />
                <p class="replace-img row" v-else>
                  <img src="../../assets/icon/img-stack.svg" alt="" />
                </p>
                <el-upload
                  class="upload-demo"
                  v-if="!$route.params.id || $route.params.id >= 1000000"
                  :http-request="uploadImages"
                  action="imagess"
                  :show-file-list="false"
                >
                  <el-button size="small" :loading="loading" type="primary"
                    >Upload Photo</el-button
                  >
                </el-upload>
              </div>
            </el-form-item>
          </div>
          <div class="item-contect">
            <el-form-item prop="PropertyType" :rules="rules.required">
              <p class="item-title">Property Type</p>
              <el-select clearable v-model="data.PropertyType" placeholder>
                <el-option
                  v-for="(item, index) in property_types"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="item-contect">
            <el-form-item prop="MLSAreaMajor" :rules="rules.required">
              <p class="item-title">Area</p>
              <el-select clearable v-model="data.MLSAreaMajor" placeholder>
                <el-option
                  v-for="(item, index) in areas"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="item-contect">
            <el-form-item prop="StreetNumber" :rules="rules.required">
              <p class="item-title">Street Number</p>
              <el-input clearable v-model="data.StreetNumber" />
            </el-form-item>
          </div>
          <div class="item-contect">
            <el-form-item prop="StreetName" :rules="rules.required">
              <p class="item-title">Street</p>
              <el-input clearable v-model="data.StreetName" />
            </el-form-item>
          </div>
          <div class="item-contect">
            <el-form-item prop="Zoning">
              <p class="item-title">Zoning</p>
              <el-select clearable v-model="data.Zoning" placeholder>
                <el-option
                  v-for="(item, index) in zonings"
                  :key="index"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="item-contect">
            <el-form-item>
              <p class="item-title">Second Dwelling</p>
              <el-select
                clearable
                v-model="data.LINK_listing_dwell2"
                placeholder
              >
                <el-option
                  v-for="(item, index) in dwel2"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="item-contect">
            <el-form-item>
              <p class="item-title" prop="LotSizeAcres">Lot (Acres)</p>
              <el-input @change="inputAcres" clearable v-model="data.LotSizeAcres" type="number" />
            </el-form-item>
          </div>
          <div class="item-contect">
            <el-form-item prop="LotSizeSquareFeet">
              <p class="item-title">Lot (SF)</p>
              <el-input @change="inputSF" clearable v-model="data.LotSizeSquareFeet" type="number" />
            </el-form-item>
          </div>
          <div class="item-contect">
            <el-form-item>
              <p class="item-title">Sewer</p>
              <el-select clearable v-model="data.Sewer" multiple placeholder>
                <el-option
                  v-for="(item, index) in sewers"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="item-contect">
            <el-form-item prop="BuildingAreaTotal">
              <p class="item-title">Living Area (SF)</p>
              <el-input v-model="data.BuildingAreaTotal" type="number" />
            </el-form-item>
          </div>
          
          <div class="item-contect">
            <el-form-item prop="BedroomsTotal">
              <p class="item-title">Bedrooms</p>
              <el-select clearable v-model="data.BedroomsTotal" placeholder>
                <el-option
                  v-for="(item, index) in 100"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="item-contect">
            <el-form-item>
              <p class="item-title">Total Rooms</p>
              <el-select clearable v-model="data.RoomsTotal" placeholder>
                <el-option
                  v-for="(item, index) in 100"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="item-contect">
            <el-form-item>
              <p class="item-title">Water Frontage</p>
              <el-select v-model="data.FrontageType" multiple placeholder>
                <el-option
                  v-for="(item, index) in FrontageTypeList"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="item-contect">
            <p class="item-title">Bathrooms</p>
            <div class="row property-detail-row">
              <div class="item-contect">
                <el-form-item prop="BathroomsFull">
                  <el-select
                    clearable
                    v-model="data.BathroomsFull"
                    placeholder="Full"
                  >
                    <el-option
                      v-for="(item, index) in 100"
                      :key="index"
                      :label="index"
                      :value="index"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="item-contect">
                <el-form-item>
                  <el-select
                    clearable
                    v-model="data.BathroomsHalf"
                    placeholder="Half"
                  >
                    <el-option
                      v-for="(item, index) in 100"
                      :key="index"
                      :label="index"
                      :value="index"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="item-contect">
            <el-form-item>
              <p class="item-title">Year Built</p>
              <el-date-picker
                v-model="data.YearBuilt"
                value-format="yyyy"
                type="year"
                placeholder
              >
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="item-contect">
            <el-form-item>
              <p class="item-title">Water Views</p>
              <el-select v-model="data.View" multiple placeholder>
                <el-option
                  v-for="(item, index) in viewList"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="item-contect">
            <el-form-item>
              <p class="item-title">Assessment Year</p>
              <el-date-picker
                v-model="data.TaxYear"
                value-format="yyyy"
                type="year"
                placeholder=""
              >
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="item-contect">
            <el-form-item>
              <p class="item-title">Waterfront</p>
              <el-select clearable v-model="data.WaterfrontYN" placeholder>
                <el-option label="Yes" value="yes"></el-option>
                <el-option label="No" value="no"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="item-contect">
            <el-form-item>
              <p class="item-title">Land Assessment</p>
              <el-input
                clearable
                v-model="data.TaxOtherAnnualAssessmentAmount"
                type="number"
              />
            </el-form-item>
          </div>
          <div class="item-contect">
            <el-form-item prop="YearBuiltEffective">
              <p class="item-title">Year Renovated</p>
              <el-date-picker
                v-model="data.YearBuiltEffective"
                type="year"
                value-format="yyyy"
                placeholder=""
              >
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="item-contect">
            <el-form-item prop="TaxAssessedValue">
              <p class="item-title">Building Assessment</p>
              <el-input
                v-model="data.TaxAssessedValue"
                clearable
                type="number"
              />
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <div class="btn row">
      <p class="btn-box back row" @click="back">
        <i class="el-icon-back"></i><span>Previous</span>
      </p>
      <p class="btn-box row" @click="next">
        <span>Next Step</span><i class="el-icon-right"></i>
      </p>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import dayjs from "dayjs";
import {
  apiGetLinkListings,
  apiUpdateLinkListingComments,
  apiCreateLink,
  apiUploadImage,
} from "../../API/api";
export default {
  data() {
    return {
      data: {
        PropertyType: "",
        MLSAreaMajor: "",
        StreetNumber: "",
        StreetName: "",
        Zoning: null,
        BedroomsTotal: null,
        LINK_listing_dwell2: null,
        LotSizeAcres: null,
        Sewer: [],
        BuildingAreaTotal: null,
        LotSizeSquareFeet: null,
        RoomsTotal: null,
        FrontageType: [],
        BathroomsFull: null,
        BathroomsHalf: null,
        YearBuilt: null,
        View: [],
        TaxYear: null,
        WaterfrontYN: null,
        TaxOtherAnnualAssessmentAmount: null,
        YearBuiltEffective: null,
        TaxAssessedValue: null,
        images: [],
      },
      value: "",
      sewers: [
        { id: "Septic Tank", name: "Septic" },
        { id: "Twn", name: "Town" },
        { id: "Unknown", name: "None" },
      ],
      property_types: [
        "Commercial",
        "Land",
        "Single Family",
        "Condo/Multi Family",
      ],
      FrontageTypeList: ["Bay/Harbor", "None", "Oceanfront", "Pnd", "Snd"],
      viewList: [
        "D/Hrb",
        "D/Oc",
        "D/Pnd",
        "D/Snd",
        "Hrb",
        "None",
        "Ocean",
        "Pasture",
        "Pond",
        "Res",
        "Snd",
        "Trees/Woods",
        "Twn",
      ],
      zonings: [
        "C1",
        "CDT",
        "CMI",
        "CN",
        "CTEC",
        "MMD",
        "LUG1",
        "LUG2",
        "LUG3",
        "None",
        "R1",
        "R2",
        "R5",
        "R10",
        "R40",
        "RC",
        "RC2",
        "RC2",
        "RCTD",
        "ROH",
        "SR2",
        "VR",
      ],
      dwel2: [
        { id: "P/Sd", name: "Potential" },
        { id: "E/Sd", name: "Existing" },
        { id: "None", name: "None" },
      ],
      rules: {
        required: [
          {
            required: true,
            message: "this information is required",
            trigger: ["blur", "change"],
          },
        ],
      },
      loading: false,
      vLoading: false,
    };
  },
  computed: mapState({
    areas: (state) => state.areas,
    propertyTypeList: (state) => state.enum.propertyTypeList,
  }),
  watch: {
    // "data.LotSizeAcres"(newV) {
    //   this.data.LotSizeSquareFeet = Math.round(this.data.LotSizeAcres * 43560);
    // },
  },
  methods: {
    ...mapActions(["getAreas", "getPropertyTypeList"]),
    inputAcres(){
      this.data.LotSizeSquareFeet = Math.round(this.data.LotSizeAcres * 43560);
    },
    inputSF(){
      this.data.LotSizeAcres = (this.data.LotSizeSquareFeet / 43560).toFixed(2);
    },
    getLinkListingDetail() {
      apiGetLinkListings({ link_id: this.$route.params.id }).then((res) => {
        this.data = res.results[0];
        if (res.results[0].PropertyType === "Commercial") {
          this.data.PropertyType = "Commercial";
        } else if (
          res.results[0].PropertyType === "1 Family" ||
          res.results[0].PropertyType === "Single Family"
        ) {
          this.data.PropertyType = "Single Family";
        } else if (res.results[0].PropertyType === "Land") {
          this.data.PropertyType = "Land";
        } else {
          this.data.PropertyType = "Condo/Multi Family";
        }
        if (this.data.TaxYear)
          this.data.TaxYear = this.data.TaxYear + "/01" + "/01";
        if (this.data.YearBuilt)
          this.data.YearBuilt = this.data.YearBuilt + "/01" + "/01";
        if (this.data.YearBuiltEffective)
          this.data.YearBuiltEffective =
            this.data.YearBuiltEffective + "/01" + "/01";
      });
    },
    next() {
      if(!this.data.LotSizeSquareFeet)  this.data.LotSizeSquareFeet = 0;
      this.$refs.linkform.validate((valid) => {
        if (valid) {
          if (this.$route.params.id) {
            if (this.data.TaxYear)
              this.data.TaxYear = dayjs(this.data.TaxYear).format("YYYY");
            if (this.data.YearBuilt)
              this.data.YearBuilt = dayjs(this.data.YearBuilt).format("YYYY");
            if (this.data.YearBuiltEffective)
              this.data.YearBuiltEffective = dayjs( this.data.YearBuiltEffective ).format("YYYY");
            apiUpdateLinkListingComments(this.$route.params.id, this.data) .then((res) => {
                this.$router.push({ name: "comparable-sales", params: this.$route.params.id, });
                this.$emit("updateTab","/comparable-sales")
                const loading = this.$loading({
                  lock: true,
                  text: "Loading",
                  spinner: "el-icon-loading",
                  background: "rgba(0, 0, 0, 0.2)",
                });
                setTimeout(() => {
                  loading.close();
                }, 1000);
              })
              .catch((err) => {
                this.$message.error(
                  err.response.data && err.response.data.detai
                    ? err.response.data.detail
                    : "Modification failed"
                );
              });
          } else {
            this.vLoading = true;
            apiCreateLink(this.data)
              .then((res) => {
                this.$emit("updateTab","/comparable-sales");
                const loading = this.$loading({
                  lock: true,
                  text: "Loading",
                  spinner: "el-icon-loading",
                  background: "rgba(0, 0, 0, 0.2)",
                });
                setTimeout(() => {
                   this.$router.push(/property-detail/ + res.ListingId +"/comparable-sales");
                  loading.close();
                }, 1000);
              })
              .finally(() => {
                this.vLoading = false;
              });
          }
        } else {
          setTimeout(() => {
            let isError = document.getElementsByClassName("is-error");
            let input = isError[0].querySelector("input");
            if (input) {
              input.focus();
            } else {
              isError[0].querySelector("textarea").focus();
            }
          }, 100);
        }
      });
    },
    back() {
      this.$store.commit("setCmaPDFUrl", "");
      this.$store.commit("setLinkId", null);
      this.$store.commit("setlinksAllId", []);
      this.$store.commit("setCompetitiveListings", []);
      this.$store.commit("setSaleListings", []);
      this.$router.push("/subject-property");
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.2)",
      });
      this.$emit("updateTab","")
      setTimeout(() => {
        loading.close();
      }, 1000);
    },
    uploadImages(param) {
      let form = new FormData();
      form.append("file", param.file);
      this.loading = true;
      apiUploadImage(form)
        .then((res) => {
          this.data.images = [];
          let obj = {};
          obj.url = res.url;
          obj.order = 1;
          obj.file_name = res.file_name;
          obj.small_url = res.small_url;
          this.data.images.push(obj);
        })
        .catch((err) => {
          this.$message("Upload failure");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    if (this.areas.length === 0) {
      this.getAreas();
    }
    if (this.propertyTypeList.length === 0) {
      this.getPropertyTypeList();
    }
    if (this.$route.params.id) {
      this.getLinkListingDetail();
    }
  },
};
</script>
<style lang="scss" scoped>
.property-detail-box {
  margin-bottom: 50px;
  .upload-img {
    width: 100%;
    margin-bottom: 10px;
    .img {
      width: 130px;
      height: 100px;
    }
    .replace-img {
      width: 130px;
      height: 100px;
      background-color: #f4f9fc;
      justify-content: center;
    }
    .upload-demo {
      margin-left: 15px;
      .el-button {
        background-color: #678993;
        color: #fff;
        border-color: #678993;
      }
    }
  }
  /deep/ input::-webkit-outer-spin-button,
  /deep/ input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  /deep/ input[type="number"] {
    -moz-appearance: textfield;
  }
  .title {
    text-align: left;
    font-family: "Roboto-Bold";
    font-size: 18px;
  }
  .address {
    text-align: left;
    font-family: "Roboto-Medium";
    font-size: 16px;
  }
  .form-box {
    padding: 20px;
    position: relative;
    &::after {
      position: absolute;
      content: "";
      border-bottom: 1px solid #e1e9ef;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }
  .property-detail-row {
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    .item-contect {
      width: calc(calc(100% - 20px) / 2);
      /deep/ .el-form-item {
        margin-bottom: 10px;
      }
      /deep/ .el-select,
      /deep/ .el-input {
        width: 100%;
      }
      .item-title {
        margin: 0;
        text-align: left;
        line-height: 20px;
      }
    }
  }
  .btn {
    -webkit-justify-content: center;
    justify-content: center;
    padding: 0 20px;
    .btn-box {
      width: 95px;
      background-color: #678993;
      height: 36px;
      line-height: 36px;
      color: #fff;
      justify-content: space-between;
      padding: 0 20px;
      border-radius: 2px;
      cursor: pointer;
    }
    .back {
      background-color: #e2eff6;
      color: #38425b;
      margin-right: 20px;
    }
  }
}
</style>